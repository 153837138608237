<template>

  <div>
    <div>
      <div class="xs-p-20 xs-fill-white">
        <div class="form-section">
          <form @submit="checkForm"
                action=""
                class="form-horizontal"
                method="post"
                novalidate="true">
            <div class="row">
              <div class="form-group">
                <label for="email" class="col-sd-4 control-label">{{$t('email')}}</label>

                <div class="col-sd-6">
                  <input id="email" type="email" ref="email" class="form-control"
                         v-bind:class="{ 'has-errors': errors.emailAddress }" name="emailAddress" v-model="emailAddress"
                         required>
                  <span v-if="errors.emailAddress" class="xs-text-right typo-l7 xs-text-red xs-ml-auto ">
                                        <span>{{ errors.emailAddress }}</span>
                                    </span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="form-group">
                <div class="col-sd-6 col-sd-offset-4">
                  <input type="submit" class="button button--primary" :value="$t('register.sent_password')">
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  computed: {},
  mounted () {
    this.eventTrackingScreenLoad(this, {screen: {'name': 'user.forgot-password', 'type': 'user'}})
    let formInfo = {
      id: 'forgot-password-form',
      name: 'forgot-password-form',
      category: 'forgot-password',
      url: window.location.href
    }
    this.eventTrackingFormLoad(formInfo)
  },
  watch: {
    emailAddress: function (newValue, oldValue) {
      this.errors.emailAddress = ''
      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('validate_email')
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
      }
    }
  },
  data () {
    return {
      emailAddress: '',
      errors: {
        'emailAddress': ''
      }
    }
  },
  methods: {
    checkForm: function (e) {
      var vm = this
      this.errors = {
        'emailAddress': ''
      }
      let data = {
        'email': this.emailAddress,
        'domain': window.location.host
      }
      if (!this.emailAddress) {
        this.errors.emailAddress = this.$t('validate_email')
        this.$refs.email.focus()
      } else if (!this.validEmail(this.emailAddress)) {
        this.errors.emailAddress = this.$t('validate_invalid_email')
        this.$refs.email.focus()
      } else {
        // check exist email
        this.eventTrackingFormSubmit({id: 'forgot-password-form'})
        this.axios.apiCustomer.checkExistEmail(data, respone => {
          let checkEmail = respone.data.data.check
          if (checkEmail === true) {
            this.errors.emailAddress = this.$t('register.sent_link_to_reset')
          }
          this.eventTrackingFormSuccess({id: 'forgot-password-form'})
        }, error => {
          if (!error.error.data.check) {
            this.errors.emailAddress = this.$t('register.sent_link_to_reset')
          }
          error = error.error
          let formInfo = {
            id: 'forgot-password-form',
            'error': {
              'code': error.code,
              'type': 'validation',
              'error': error.message
            }
          }
          vm.eventTrackingFormError(formInfo)
        })
      }

      e.preventDefault()
    },
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  }
}
</script>
